import { axios } from "./../plugins/axios";

export function getActivity(data) {
  return axios.request({
    method: "POST",
    url: "/api/Activity/activity_list",
    params: {
      ...data
    }
  });
}
/* 预约接口 */
export function sign_up(data) {
  return axios.request({
    method: "POST",
    url: "/api/Activity/sign_up",
    params: {
      ...data
    }
  });
}
/* 报名确认页 */
export function activity_settlement(data) {
  return axios.request({
    method: "POST",
    url: "/api/Activity/activity_settlement",
    params: {
      ...data
    }
  });
}
/* 缘分墙 */
export function user_list(data) {
  return axios.request({
    method: "POST",
    url: "/api/Follow/user_list",
    params: {
      ...data
    }
  });
}
/* 心仪用户 */
export function follow(data) {
  return axios.request({
    method: "POST",
    url: "/api/Follow/follow",
    params: {
      ...data
    }
  });
}
/* 个人中心活动列表 */
export function activityList(data) {
  return axios.request({
    method: "POST",
    url: "/api/list/list",
    params: {
      ...data
    }
  });
}
/* 个人中心活动列表 */
export function signUp(data) {
  return axios.request({
    method: "POST",
    url: "/api/Activity/settlement",
    params: {
      ...data
    }
  });
}
/* 获取报名订单号 */
export function activity_payment(data) {
  return axios.request({
    method: "POST",
    url: "/api/Activity/activity_payment",
    params: {
      ...data
    }
  });
}
/* 活动详情 */
export function activity_info(data) {
  return axios.request({
    method: "POST",
    url: "/api/Activity/activity_info",
    params: {
      ...data
    }
  });
}
/* 支付成功查看二维码 */
export function getCode(data) {
  return axios.request({
    method: "POST",
    url: "/api/ActivityOrder/getCode",
    params: {
      ...data
    }
  });
}
/* 券码详情 */
export function codeDetail(data) {
  return axios.request({
    method: "POST",
    url: "/api/ActivityOrder/order_info",
    params: {
      ...data
    }
  });
}
/* 订单列表 */
export function order_list(data) {
  return axios.request({
    method: "POST",
    url: "/api/ActivityOrder/order_list",
    params: {
      ...data
    }
  });
}
/* 订单总计 */
export function order_count(data) {
  return axios.request({
    method: "POST",
    url: "/api/ActivityOrder/order_count",
    params: {
      ...data
    }
  });
}
/* 退款 */
export function return_money(data) {
  return axios.request({
    method: "POST",
    url: "/api/ActivityOrder/return_money",
    params: {
      ...data
    }
  });
}